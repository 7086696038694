<template>
    <div class="credits" :style="{'--bcolor':article[0].cor}">
        <div class="content">
            <label @click="updateOpenCredits" class="close-credits">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.799 1.41421L17.3848 0L0.414203 16.9706L1.82842 18.3848L18.799 1.41421Z" fill="white" />
                    <path d="M16.9706 18.4142L18.3848 17L1.4142 0.0294375L-1.03712e-05 1.44365L16.9706 18.4142Z"
                        fill="white" />
                </svg>
            </label>
            <div class="container">
                <h3>Full credits</h3>
                <ul v-if="article[0].creditos">
                    <li v-for="(li, index) in article[0].creditos" :key="index">
                        {{ li.titulo }}
                        <ul v-if="li.itens">
                            <li v-for="(it, idx) in li.itens" :key="idx">{{ it.texto }}</li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "appCredit",
    props: ["article"],
    methods: {
        updateOpenCredits() {
            this.$emit("updateOpenCredits", null);
        }
    }
}
</script>

<style lang="less">

</style>