<template>
    <h1 class="logo">
        El Pinheiro
        <img v-show="!openArticle" src="@/assets/img/logo.svg">
    </h1>
</template>
  
<script>
export default {
    name: 'appLogo',
    props: ['openArticle'],
}
</script>

<style lang="less">
</style>