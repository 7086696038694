<template>
    <div class="lightbox">
        <div class="content">
            <label @click="updateOpenArticle" class="close">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.799 1.41421L17.3848 0L0.414203 16.9706L1.82842 18.3848L18.799 1.41421Z" fill="white" />
                    <path d="M16.9706 18.4142L18.3848 17L1.4142 0.0294375L-1.03712e-05 1.44365L16.9706 18.4142Z"
                        fill="white" />
                </svg>
            </label>
            <div class="logo">
                <div class="sticky">
                    <img src="@/assets/img/logo.svg">
                </div>
            </div>
            <div class="container">
                <video v-if="article[0].video" class="principal" :src="article[0].video" :poster="article[0].video_cover" controls></video>
                <img v-else class="principal" :src="article[0].imagem">
                <div class="flex">
                    <div>
                        <h2>{{ article[0].title }}</h2>
                        <div v-html="article[0].descricao"></div>
                    </div>
                    <ul>
                        <li v-for="(li, index) in article[0].lista" :key="index">
                            {{ li.titulo }}
                            <ul v-if="li.itens">
                                <li v-for="(it, idx) in li.itens" :key="idx">{{ it.texto }}</li>
                            </ul>
                        </li>
                        <li v-if="article[0].creditos">
                            <ul>
                                <li><label class="fullcredits" @click="updateOpenCredits">View full credits</label></li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div v-html="article[0].conteudo"></div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
    name: "appLightbox",
    components: {
		Footer
	},
    props: ["article"],
    methods: {
        updateOpenArticle() {
            this.$emit("updateOpenArticle", null);
        },
        updateOpenCredits() {
            this.$emit("updateOpenCredits", this.article[0].id);
        }
    }
}
</script>

<style lang="less">
@keyframes showContent{
	from{
		transform: translateY(1200px);
	}
	to{
		transform: translateY(0px);
	}
}
.lightbox {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 150;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .transition;
    transition-duration: .4s;
    .bescuro;
    .container {
        position: relative;
        z-index: 55;
        transform: translateY(1200px);
        animation: showContent 600ms forwards ease-in-out;
		animation-delay: 5ms;
    }

    .content {
        height: 100%;
        width: 100%;
        overflow-y: auto;
        position: relative;
        z-index: 300;
        padding-bottom: 100px;

        .close {
            .ib;
            padding: 20px;
            position: fixed;
            top: 0;
            right: 0;
            z-index: 555;
        }

        .principal{
            width: 100%;
        }

        img{ max-width: 100%; height: auto; }

        @media (max-width: 1199px) {
            .logo {
                .tl;

                .sticky {
                    img {
                        width: 70%;
                    }
                }
            }
        }

        @media (min-width: 1200px) {
            .logo {
                display: block;
                margin-bottom: calc( (25vh + 127px) * -.3);;
                padding-top: 0;
                .tc;
                overflow: visible;

                .sticky {
                    width: 100%;
                    height: 0;
                    position: -webkit-sticky;
                    position: sticky;
                    top: 0;
                    padding-top: 20px;

                    img {
                        height: calc(25vh - 40px);
                    }
                }
            }
        }

        .container {
            padding: 0 15px;

            video {
                aspect-ratio: 16 / 10;
                .mb1;
            }

            img {
                .mb1;
            }

            h2 {
                margin-bottom: 15px;
            }

            p {
                .t16;
                .mb1;
            }

            ul {
                margin: 15px 0;
                .p0;

                li {
                    .t16;
                    .ccinza;
                    .mb1;

                    ul {
                        .m0;

                        li {
                            .cbranco;
                            .mb05;
                            .fullcredits{
                                .uc; text-decoration: underline !important;
                            }
                        }
                    }
                }
            }

            .flex {
                @media(min-width: 1200px) {
                    display: flex;
                    justify-content: space-between;
                    gap: 50px;

                    ul {
                        flex: 30% 0 0;
                    }
                }
            }
        }
    }
}
</style>