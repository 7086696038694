<template>
	<nav id="navigation">
		<ul class="menu">
			<li><a @click="$emit('updateOpenAbout', true)">About</a></li>
			<li><a @click="$emit('updateOpenContact', true)">Contact</a></li>
		</ul>
		<p id="current">
			<span class="show-left">{{ jobs[0].title }}</span>
		</p>
		<ul class="ms">
			<li><a href="http://www.instagram.com/elpinheiro" target="_blank">I<span>NSTA</span>G<span>RAM</span></a></li>
			<li><a href="https://www.behance.net/elpinheiro" target="_blank">BE<span>HANCE</span></a></li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'appNav',
	props: ['jobs']
}
</script>

<style lang="less">
nav#navigation {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
	ul {
		.ib; .m0; .p0;
		li {
			.ib;
			a {
				.bl; .t16; .uc; .p5;
			}
		}
		@media (max-width: 1199px) {
			&.ms li a span{
				display: none;
			}
		}
	}

	#current {
		.tc;
		position: relative;

		span {
			.ccinza;
			display: none;
			width: max-content;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(calc(-50%), calc(-50%));

			&.show-left {
				.ib;
				animation: showLeft 600ms ease-in-out;
			}

			&.show-right {
				.ib;
				animation: showRight 600ms ease-in-out;
			}

			&.hide-left {
				.ib;
				animation: hideLeft 600ms ease-in-out forwards;
			}

			&.hide-right {
				.ib;
				animation: hideRight 600ms ease-in-out forwards;
			}
		}

		@media (max-width: 1199px) {
			display: none;
		}
	}

	@media (min-width: 1200px) {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 0 30px 30px;
		height: calc(5vh + 60px);
		align-items: flex-end;
	}
}
</style>
