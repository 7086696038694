<template>
	<Logo :openArticle="openArticle"></Logo>
	<Nav v-if="jobs.length > 0" :jobs="jobs" @updateOpenContact="updateOpenContact" @updateOpenAbout="updateOpenAbout"></Nav>
	<Contact v-if="openContact" @updateOpenContact="updateOpenContact"></Contact>
	<Carousel v-if="jobs.length > 0" :openArticle="openArticle" :mobile="mobile" :jobs="jobs" @updateOpenArticle="updateOpenArticle" />
	<Lightbox v-if="openArticle" :article="articleData()" @updateOpenArticle="updateOpenArticle" @updateOpenCredits="updateOpenCredits" />
	<About v-if="openAbout && about.length > 0" :about="about" @updateOpenAbout="updateOpenAbout" />
	<transition name="credit">
		<Credit v-if="openCredits" :article="articleData()" @updateOpenCredits="updateOpenCredits" />
	</transition>
</template>

<script>
import axios from 'axios';
import Logo from '@/components/Logo.vue';
import Nav from '@/components/Nav.vue';
import Carousel from '@/components/Carousel.vue';
import Lightbox from '@/components/Lightbox.vue';
import Credit from '@/components/Credit.vue';
import Contact from '@/components/Contact.vue';
import About from '@/components/About.vue';

export default {
	name: 'App',
	components: {
		Carousel, Nav, Logo, Lightbox, Credit, Contact, About,
	},
	data() {
		return {
			mobile: window.innerWidth >= 1200 ? false : true,
			openArticle: null,
			openCredits: null,
			openContact: false,
			openAbout: false,
			jobs: [],
			about: [],
		}
	},
	methods: {
		articleData() {
			let article = this.jobs.filter((a) => 'lightbox-' + a.id === this.openArticle);
			return article;
		},
		esc(e) {
			if (e.key === "Escape") {
				if (this.openAbout) {
					this.openAbout = false;
				}
				if (this.openContact) {
					this.openContact = false;
				}
				if (this.openCredits) {
					this.openCredits = null;
				}
				else if (this.openArticle) {
					this.openArticle = null;
				}
			}
		},
		updateOpenArticle(id) {
			this.openArticle = id;
		},
		updateOpenCredits(id) {
			this.openCredits = id;
		},
		updateOpenContact(bool) {
			this.openContact = bool;
		},
		updateOpenAbout(bool) {
			this.openAbout = bool;
		},
		resize() {
			this.mobile = window.innerWidth >= 1200 ? true : false;
		}
	},
	mounted() {
		document.onkeyup = this.esc;
		window.addEventListener('resize', this.resize);
		axios.post(
			'https://elpinheiro.tv/painel/',
			{
				token: 'xUX^KDuLQF+{>KbW9tqP))f|k-C%w~ukTAwMz0hH(-T>fhrcL::$%whuJR&:4',
				fields: [
					'imagem', 'descricao', 'lista', 'creditos', 'conteudo', 'cor', 'video', 'video_cover',
				],
				query: {
					post_type: 'post',
					posts_per_page: -1
				},
			},
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}
		).then(res => {
			this.jobs = res.data.posts;
		});

		axios.post(
			'https://elpinheiro.tv/painel/',
			{
				token: 'xUX^KDuLQF+{>KbW9tqP))f|k-C%w~ukTAwMz0hH(-T>fhrcL::$%whuJR&:4',
				fields: [
					'destaque', 'conteudo', 'galeria', 'awards',
				],
				query: {
					post_type: 'page',
					name: 'sobre',
					posts_per_page: 1
				},
			},
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded'
				}
			}
		).then(res => {
			this.about = res.data.posts;
		});
	},
	created() {
		
	}
}
</script>

<style lang="less">
@keyframes creditShow {
	from{ transform: translateX(100%); }
	to{ transform: translateX(0%); }
}
@keyframes creditHide {
	from{ transform: translateX(0%); }
	to{ transform: translateX(100%); }
}
.credit-enter-active{
	animation: creditShow 400ms ease-in;
}
.credit-leave-active{
	animation: creditHide 400ms ease-out;
}
</style>