<template>
    <div id="about" class="lightbox">
        <div class="content">
            <label @click="updateOpenAbout" class="close">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18.799 1.41421L17.3848 0L0.414203 16.9706L1.82842 18.3848L18.799 1.41421Z" fill="white" />
                    <path d="M16.9706 18.4142L18.3848 17L1.4142 0.0294375L-1.03712e-05 1.44365L16.9706 18.4142Z"
                        fill="white" />
                </svg>
            </label>
            <div class="logo">
                <div class="sticky">
                    <img src="@/assets/img/logo.svg">
                </div>
            </div>
            <div class="container">
                <img class="principal" :src="about[0].destaque">
                <h2>{{ about[0].title }}</h2>
                <div v-html="about[0].conteudo"></div>
                <br><br><br>

                <div id="slider" class="slider"
                draggable="true"
                v-on:drag="drag"
                v-on:dragstart="dragStart"
                v-on:dragend="dragEnd">
                    <div class="wrapper">
                        <div id="slides" ref="scroll" class="slides">
                            <span class="slide" ref="jobs" v-for="gal in about[0].galeria" :key="gal">
                                <img class="principal" :src="gal">
                            </span>
                        </div>
                    </div>
                </div>


                <div v-html="about[0].awards"></div>
            </div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue';

export default {
    name: "appAbout",
    components: {
		Footer
	},
    props: ['about'],
    data() {
		return {
			windowMinSize: 1200,
			allowDrag: true,
			dragging: false,
			wheeling: false,
			scrollT: 0,
			lastScrollT: 0,
			screenX: 0,
			moveX: 0,
			animationDuration: 400,
			articles: null,
			current: document.getElementById('current'),
		}
	},
    methods: {
        updateOpenAbout() {
            this.$emit("updateOpenAbout", false);
        },
        dragStart(e){
			const blankCanvas = document.createElement('canvas');
			e.dataTransfer.setDragImage(blankCanvas, 0, 0);
			this.screenX = e.screenX;
		},
		drag(e){
			if(
				window.innerWidth >= this.windowMinSize
				&& e.screenX != 0
			){
				this.scrollT += (e.screenX - this.screenX) / .8;
				this.moveX += (e.screenX - this.screenX) / .8;
				this.screenX = e.screenX;
				this.translate( this.scrollT);
			}
		},
		dragEnd(){
			this.finishAnimation();
			this.screenX = 0;
		},
        translate(x, duration = this.animationDuration) {
			this.$refs.scroll.animate({
				transform: `translateX(${x}px)`
			}, { duration: duration, fill: "forwards", ease: "linear" });
		},
        finishAnimation() {
            this.steps = this.$refs.jobs[0].getBoundingClientRect().width + 40;
            let selected = Math.round(this.lastScrollT / this.steps);
            let direction = 0;
            if (this.moveX > 0) {
                direction = 1;
            }
            if (this.moveX < 0) {
                direction = -1;
            }

            this.scrollT = (selected + direction) * this.steps;
            let max = document.querySelectorAll('.slide').length;
            this.scrollT = this.scrollT > 0 ? 0 : this.scrollT;            
            this.scrollT = this.scrollT < (max-1)*-this.steps ? (max-1)*-this.steps : this.scrollT;            

            this.lastScrollT = this.scrollT;
            this.translate(this.scrollT);

            this.moveX = 0;
		},
    }
}
</script>

<style lang="less">
#about{
    @media (min-width: 1200px) {
        .container{ max-width: 990px; }
    }
    h2{
        font-size: 48px; margin-top: 20px; margin-bottom: 30px !important;
        line-height: 100%;
        @media (min-width: 1200px) {
            font-size: 100px; margin-bottom: 40px !important;
        }
        @media (min-width: 1500px) {
            transform: translateX(-110px);
        }
    }
    p{
        max-width: 600px;
    }
    h3{
        .m0;
        @media (min-width: 1200px){
            .t48;
        }
    }

    .content{
        overflow-x: hidden;
    }
    @media(min-width: 1200px){
        #slider{
            width: 100%; position: relative;
            .no-selection;
            .wrapper{
                #slides{
                    display: flex; align-items: center; gap: 40px;
                    width: max-content;
                    .slide{
                        max-width: 990px;
                        img{ pointer-events: none; }
                    }
                }
            }
        }
    }
}
</style>