<template>
	<div v-bind:draggable="draggable" class="movearea"
		v-on:wheel="wheel"
		v-on:drag="drag"
		v-on:dragstart="dragStart"
		v-on:dragend="dragEnd">
		<section id="jobs" ref="jobs">
			<div id="scroll" ref="scroll">
				<Job
					v-for="item in jobs"
					:key="item.id"
					:job="item"
					:allowDrag="allowDrag"
					@updateOpenArticle="updateOpenArticle"
					@jobDone="jobDone" />
			</div>
		</section>
	</div>
</template>

<script>
import Job from '@/components/Job.vue'

export default {
	name: 'appCarousel',
	components: {
		Job
	},
	props: ['jobs', 'mobile'],
	data() {
		return {
			windowMinSize: 1200,
			allowDrag: true,
			dragging: false,
			wheeling: false,
			scrollT: 0,
			lastScrollT: 0,
			screenX: 0,
			moveX: 0,
			animationDuration: 400,
			articles: null,
			current: document.getElementById('current'),
		}
	},
	methods: {
		dragStart(e){
			const blankCanvas = document.createElement('canvas');
			e.dataTransfer.setDragImage(blankCanvas, 0, 0);
			this.screenX = e.screenX;
			this.dragging = true;
		},
		drag(e){
			if(
				window.innerWidth >= this.windowMinSize
				&& e.screenX != 0
			){
				this.scrollT += (e.screenX - this.screenX) / .8;
				this.moveX += (e.screenX - this.screenX) / .8;
				this.screenX = e.screenX;
				this.translate( this.scrollT);
			}
		},
		dragEnd(){
			this.finishAnimation();
			this.screenX = 0;
		},
		wheel(e) {
			if (
				window.innerWidth >= this.windowMinSize
				&& !this.dragging
				&& !this.wheeling
				&& this.allowDrag
			) {
				this.dragging = true;
				this.wheeling = true;
				this.scrollT += e.deltaY * -2;
				this.moveX = e.deltaY * -2;
				this.translate(this.scrollT);
				setTimeout(() => { this.wheeling = false; }, this.animationDuration / 2);
				this.finishAnimation();
			}
		},
		translate(x, duration = this.animationDuration) {
			this.$refs.scroll.animate({
				transform: `translateX(${x}px)`
			}, { duration: duration, fill: "forwards", ease: "linear" });
		},
		finishAnimation() {
			if (this.dragging) {
				this.steps = this.$refs.jobs.getBoundingClientRect().width;
				let selected = Math.round(this.lastScrollT / this.steps);
				let direction = 0;
				if (this.moveX > 0) {
					direction = 1;
				}
				if (this.moveX < 0) {
					direction = -1;
				}

				this.scrollT = (selected + direction) * this.steps;
				this.translate(this.scrollT);
				this.allowDrag = false;

				this.adjustCurrent(selected+direction, direction);

				setTimeout(
					() => {
						this.adjustInfiniteLoop(direction);
						this.allowDrag = true;
					},
					this.animationDuration + 1
					//prevent cluttering
				);

				this.moveX = 0;
			}
			this.dragging = false;
		},
		resize() {
			this.scrollT = 0;
			this.translate(this.scrollT, 0);
		},
		updateOpenArticle(id) {
			this.$emit('updateOpenArticle', id);
		},
		adjustCurrent(num, direction) {
			this.articles = document.querySelectorAll('#scroll article');
			for(var i=0; i<this.articles.length; i++){
				this.articles[i].classList.remove('onview');
			}
			this.articles[Math.abs(num)].classList.add('onview');

			
			let title = this.articles[Math.abs(num)].children[0].children[1].children[0].cloneNode(true);
			title.className = direction > 0 ? 'show-right' : 'show-left';
			this.current.append(title);

			let prev = document.getElementById('current').firstChild;
			prev.classList.remove('show-left');
			prev.classList.remove('show-right');
			prev.remove();
		},
		runInfiniteLoop() {
			this.articles = document.querySelectorAll('#scroll article');
			for(var i=this.articles.length-1; i>=this.articles.length/2; i--){
				let clone = this.articles[i];
				this.$refs.scroll.prepend(clone);
			}
			this.steps = this.$refs.jobs.getBoundingClientRect().width;
			this.scrollT = Math.floor(this.articles.length/2)*this.steps*-1;
			this.articles = document.querySelectorAll('#scroll article');
			this.lastScrollT = this.scrollT;
			this.translate(this.scrollT, 0);
		},
		adjustInfiniteLoop(dir){
			let articles = document.querySelectorAll('#scroll article');
			if(dir > 0){
				this.$refs.scroll.prepend(articles[articles.length-1]);
				this.scrollT -= this.steps;
			}
			else{
				this.$refs.scroll.append(articles[0]);
				this.scrollT += this.steps;
			}
			this.lastScrollT = this.scrollT;
			this.translate(this.scrollT, 0);
		},
		jobDone(jobId) {
			if(!this.mobile && this.jobs[this.jobs.length-1].id == jobId){
				this.runInfiniteLoop();
			}
		}
	},
	computed: {
		draggable: function() {
			return this.allowDrag ? 'true' : 'false';
		}
	},
}
</script>

<style lang="less">
@media all {
	#jobs {
		#scroll {
			article {
				.m0; padding: 15px 17px;
				.thumb {
					.bl;
					img {
						width: 100%;
						.no-selection;
						pointer-events: none;
					}
				}
				h2 {
					.ccinza; .t16; .m0;
					span {
						.ccinza; .t16; .bl;
					}
				}
			}
		}
	}
}
@media (min-width: 1200px) {
	#jobs {
		position: relative;
		margin: auto;
		height: calc(70vh - 60px);
		aspect-ratio: 16 / 10;
		width: calc(16 / 10 * 70vh - 60px);
		max-width: 100%;
		display: flex;
		align-items: center;

		#scroll {
			display: flex;
			width: max-content;
			overflow: visible;
			article {
				height: calc(10 / 16 * 100vw);
				max-height: calc(70vh - 60px);
				width: calc(16 / 10 * 70vh - 60px);
				max-width: 100vw;
				aspect-ratio: 16 / 10;
				overflow: hidden;

				label{
					height: 100%;
					width: 100%;
					h2 {
						display: none;
					}
					img{
						height: 100%;
						width: 100%;
					}
				}
			}
		}
	}
}
</style>