<template>
	<nav id="footer">
		<div>
			<a href="mailto:romulo@elpinheiro.tv">romulo@elpinheiro.tv</a>
			<span>
				<svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M0 14.1934V0.804688H19.7642V14.1934H0ZM1.6598 7.48892L9.88209 12.4679L18.1044 7.48892L9.88209 2.50991L1.6598 7.48892ZM9.88206 9.24386C10.9946 9.24386 11.8966 8.34193 11.8966 7.22935C11.8966 6.11677 10.9946 5.21484 9.88206 5.21484C8.76948 5.21484 7.86755 6.11677 7.86755 7.22935C7.86755 8.34193 8.76948 9.24386 9.88206 9.24386Z" fill="white"/>
				</svg> Rio de Janeiro © 2022
			</span>
		</div>
		<ul class="ms">
			<li><a href="http://www.instagram.com/elpinheiro" target="_blank">I<span>NSTA</span>G<span>RAM</span></a></li>
			<li><a href="https://www.behance.net/elpinheiro" target="_blank">BE<span>HANCE</span></a></li>
		</ul>
	</nav>
</template>

<script>
export default {
	name: 'appFooter'
}
</script>

<style lang="less">
#footer{
	display: flex; width: 100%; padding: 0 35px 15px;
	align-items: center; justify-content: space-between;
	transform: translateY(85px);
	@media (min-width: 1200px) {
		justify-content: space-between;
	}

	div{
		display: flex; flex-direction: column;
		a{ .ib; margin-bottom: 10px; }
		@media (min-width: 1200px) {
			flex-direction: row;
			flex: 55% 0 0;
			justify-content: space-between;
			span{
				order: 1;
			}
			a{
				order: 2; margin-bottom: 0;
			}
		}
	}

	ul {
		.ib; .m0; .p0; width: 30px;
		li {
			.ib;
			a {
				.bl; .t16; .uc; .p5;
			}
		}
		@media (min-width: 1200px) {
			width: max-content;
		}
		@media (max-width: 1199px) {
			&.ms li a span{
				display: none;
			}
		}
	}
}
</style>
