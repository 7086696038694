<template>
    <article :class="'article article-'+job.id">
        <label @click="clicked('lightbox-'+job.id)" :data-for="'lightbox-'+job.id" class="thumb">
            <img draggable="false" :src="job.imagem">
            <h2><span>{{ job.title }}</span></h2>
        </label>
    </article>
</template>
  
<script>
export default {
    name: 'appJob',
    props: ['job', 'allowDrag'],
    methods: {
        clicked(id) {
            if(this.allowDrag){
                this.$emit('updateOpenArticle', id);
            }
        }
    },
    mounted() {
        this.$emit('jobDone', this.job.id);
    }
}
</script>

<style lang="less">

</style>